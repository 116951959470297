
/* Style for the itemcircles container */
.wrapped-circles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
  }
  
  /* Style for the grid circles */
  .course-circle {
    text-align: center !important;
    margin: 20px;
    margin-bottom: 6px !important;
    cursor: pointer;
  }
  
  #p {
    text-align: center !important;
    padding: 0.5rem 0rem !important;
    max-width: 150px;
  }
  
  .course-circle img {
    background-color: #fff;
    width: 150px; 
    height: 150px;
    border-radius: 50%; 
    object-fit: cover; 
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4); 
    margin: 3px;
  }

  @media (max-width: 768px) {
    .course-circle img {
      width: 100px !important;
      height: 100px !important;
    }
    #p {
      font-size: 0.8rem !important;
      max-width: 100px !important;
    }
  }
  
  .course-circle p {
    margin-top: 3px;
  }
  
  .course-circle img:hover {
    outline: 2px solid #00C2CB;
  }
  
  .student-open-for-work img {
    outline: 3px groove #00CB4D;
  }

  .student-no-linkedin img {
    outline: 3px groove #ffff00;
  }

  .student-profile {
    border-radius: 25%;
  }
  
  .skill-oval {
    background-color: #00C2CB;
    color: #000000;
    border-radius: 50px;
    padding: 5px 10px;
    margin: 5px;
    display: inline-block;
  }
  
  .skill-oval:hover {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4); 
    font-style: italic;
  }
  
  .skills-container {
    display: flex;
    flex-wrap: wrap;
  }
  